<!--Pagina de participación finalizada-->
<template >
    <v-container class="align-start black fill-height ma-0 pa-0" fluid >
    <v-row class="fbot d-flex align-stretch my-0" justify="center" no-gutters>
        <v-col cols="12" md="11" class="align-self-start">
            <menus/>
        </v-col>
        <!--Títulos-->
        <v-col cols="12" md="11"  class="fondo" justify="center">
            <h1 class="white--text text-center mt-10" style="font-size: 40pt">
                GRACIAS <span class="bordeado overflow-hidden"> {{nameUser}} </span>
            </h1>    
            <h2 class="white--text text-center">
                POR DESBLOQUEAR
            </h2>
            <h2 class="white--text text-center">
                TU VERDADERO YO
            </h2>
            <h1 class="white--text text-center mb-15">
                &nbsp;
            </h1>
            <!--Tarjeta de la imagen final-->
            <v-responsive :aspect-ratio="1"  min-width="200" max-width="200" class="inclinado mt-n15 mb-n10 mx-auto" style="z-index: 2" >
                        <v-card rounded="0"
                        color="black">
                            <v-img
                                :aspect-ratio="1"
                                width="80%"
                                min-width="200"
                                class="my-auto"
                                :src="ifinalt == '1'?'/imagen/archivosFinales/'+ifinalp:'/imagen/archivosFinales/'+ifinalg"
                            >
                            <template v-slot:placeholder>
                            <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular
                                indeterminate
                                color="becks"
                            ></v-progress-circular>
                            </v-row>
                        </template>
                            </v-img>
                        </v-card>
                    </v-responsive>
                <v-card class="mx-auto justify-center d-flex mt-n10 pt-10 flex-wrap mb-16" max-width="450" rounded="xl" color="rgba(0,0,0,.81)">
                    <a :href="playlist" target="_blank" class="d-none" ref="enlace">No lo veras nunca</a>
                    
                    <v-btn
                      class="mt-9 mb-10"
                      elevation="2"
                      color="becks"
                      rounded
                      @click="$refs.enlace.click()"
                    >
                      <v-icon size="35">mdi-spotify</v-icon>&nbsp; Escucha esta playlist: <br>"{{playlistNombre}}"
                    </v-btn>
                <span style="width: 100%"/>
                <v-spacer></v-spacer>
                <!-- Aqui esta el codigo de los tipo de archivo para descargar
                <v-btn
                    dark
                    x-large
                    elevation="2"
                    text
                >
                    <v-icon x-large>mdi-youtube</v-icon>
                </v-btn><v-btn
                    dark
                    x-large
                    elevation="2"
                    text
                >
                    <v-icon x-large>mdi-image</v-icon>
                </v-btn>
                <v-btn
                    dark
                    x-large
                    elevation="2"
                    text
                >
                    <v-icon x-large>{{ icons.mdiFileGifBox }}</v-icon>
                </v-btn>
                <v-spacer class="my-3"/>
                <div class="font-weight-black white--text">
                    ¡COMP&Aacute;RTELA!
                </div>
                -->
                <v-spacer class="my-3"/>
                <!-- empieza el ocultado de los eventos
                <div>
                    <div class="font-weight-black white--text  text-center my-10">
                        SELECCIONA UN EVENTO AL QUE TE GUSTAR&Iacute;A IR:
                    </div>
                    <fiesta  
                        v-for="(item, i) in party"
                        :key="i"
                        :dia="item.event_day"
                        :mes="item.event_month"
                        :lugar="item.event_hour + ' ' + item.event_place"
                        :artista="item.event_name"
                        :id="item.id"
                        :provider_id="provider_id"
                    />
                </div>
                -->
                    <!-- <fiesta dia="10" mes="NOV" artista="ESTEMAN" lugar="SÁBADO 8:00PM - FORO SOL, CIUDAD DE MÉXICO" /> -->
                
                </v-card>
        </v-col>
        </v-row>
    </v-container>

</template>
<script>
import { mdiFileGifBox } from "@mdi/js";
import menus from "../components/menus.vue";
import fiesta from "../components/fiesta.vue";
import axios from "axios";

export default {
    name: "FotoTerminada",
    components: {
    menus,
    fiesta,
},
data: () => ({
    icons: {
    mdiFileGifBox,
    },
    dialog: false,
    control: 1,
    sheet: false,
    ifinalg: "",
    ifinalm: "",
    ifinalp: "",
    ifinalt: "",
    party: [],
    urlimage: "",
    ruta: "https://unlockbecks.earlybirdcontent.com/Galeria/",
    nameUser: "",
    playlist: "",
    playlistNombre: ""
  }),
  methods: {
    //Funciones de compartir en redes sociales, ya no se usan
    fbs() {
      if (this.ifinalt == 1) {
        var shareURL = this.ruta + this.ifinalp;
      } else {
        var shareURL = this.ruta + this.ifinalg;
      }

      var t = "Unlock Becks";
      window.open(
        "http://www.facebook.com/sharer.php?u=" +
          encodeURIComponent(shareURL) +
          "&t=" +
          encodeURIComponent(t) +
          "?img="+ shareURL,
        "sharer",
        "toolbar=0,status=0,width=626,height=436"
      );

      axios
        .post("share.php", {
          urlimage1: this.ifinalp,
          urlimage2: this.ifinalg,
        })
        .then(function (response) {
        //   console.log(response);
        })
        .catch((error) => {});
    },
    whts() {
      if (this.ifinalt == 1) {
        var shareURL = this.ruta + this.ifinalp;
      } else {
        var shareURL = this.ruta + this.ifinalg;
      }

      var t = "Unlock Becks";

      // Opening URL
      window.open(
        "https://wa.me/?text=" +
          encodeURIComponent(shareURL) + ' ' +
          encodeURIComponent(t),
        "_blank"
      );

      axios
        .post("share.php", {
          urlimage1: this.ifinalp,
          urlimage2: this.ifinalg,
        })
        .then(function (response) {
        //   console.log(response);
        })
        .catch((error) => {});
    },
    twttr() {
      if (this.ifinalt == 1) {
        var shareURL = this.ruta + this.ifinalp;
      } else {
        var shareURL = this.ruta + this.ifinalg;
      }

      var t = "Unlock Becks";

      window.open(
        "http://twitter.com/share?url=" +
          encodeURIComponent(shareURL) +
          "&text=" +
          encodeURIComponent(t),
        "",
        "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
      );

      axios
        .post("share.php", {
          urlimage1: this.ifinalp,
          urlimage2: this.ifinalg,
        })
        .then(function (response) {
        //   console.log(response);
        })
        .catch((error) => {});
    },
    cambiarP(lugar) {
      axios
        .get("/")
        .then((response) => this.$router.push({ name: lugar }))
        .catch((error) => console.log("Hubo un error: " + error));
    },
    // esta funcion solo MUESTRA los eventos disponibles
    async iniciar() {
      this.ifinalt = sessionStorage.imagenFinalTipo;
      this.ifinalg = sessionStorage.imagenFinalGif;
      this.ifinalp = sessionStorage.imagenfinalJpeg;
      this.ifinalm = sessionStorage.videoFinalMp4;
      this.playlist = sessionStorage.playlist;
      this.playlistNombre = sessionStorage.getItem('playlistNombre');

      await axios
        .post("events.php", {})
        .then(function (response) {
          // console.log(response)
          // console.log(response.data)

          sessionStorage.array_data = JSON.stringify(response.data);
        })
        .catch((error) => {
          // console.log(error)
        });

      this.party = JSON.parse(sessionStorage.array_data);
      // console.log(this.party)
    },
    async userName(){
        await axios
        .post("user_name.php", {})
        .then(function (response) {
        //   console.log(response)
        //   console.log(response.data)
        sessionStorage.nombre = JSON.stringify(response.data.completeName);
        })
        .catch((error) => {
        //   console.log(error)
        });
        this.nameUser = JSON.parse(sessionStorage.nombre);
    },
    checkCookie(){
      axios
          .post("check_cookie.php", {
          
          })
          .then(function (response) {
            // console.log(response.data.message)
  
            if(response.data.message != 'galleta'){
              window.location.replace("/Edad");
            }
  
          })
          .catch((error) => {
              // console.log(error)
          });
    }
  },
  created() {
    this.iniciar();
    this.userName();
    this.checkCookie();
  },
};
</script>
<style scoped>
.fondo {
  background-image: url("../assets/FotoTerminada.jpg");
  background-size: cover;
  background-position-x: center;
}


@media (max-width: 960px) {
  .fondo {
    background-image: url("../assets/FotoTerminadaMobile.jpg");
    background-size: contain;
    background-position-y: 20vw;
  }
  .fbot {
  background-image: url("../assets/Fbot.png");
    background-position-x: 5%;
    background-position-y: bottom;
    background-size: contain;
  }
}
.bordeado {
  color: transparent;
  -moz-text-stroke-width: 0.5px;
  -webkit-text-stroke-width: 0.5px;
  -moz-text-stroke-color: #000000;
  -webkit-text-stroke-color: #fff;
}
.inclinado {
  transform: rotate(-10deg);
}
</style>
